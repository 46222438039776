var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('vue-select',{attrs:{"value":_vm.category,"placeholder":_vm.placeholder,"options":_vm.categories,"filterable":false,"label":"fullName"},on:{"search":_vm.search,"input":_vm.onChange},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var id = ref.id;
var name = ref.name;
return [_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},[_c('div',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(name)+" "),(!id)?_c('span',[_vm._v("("+_vm._s(_vm.$t('project.newCategory'))+")")]):_vm._e()])])],1)]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" "+_vm._s(_vm.$t('validation.threeChrForSearch'))+" ")]}}])})],1)],1),_c('b-row',[_c('b-col',{attrs:{"xs":"12"}},_vm._l((_vm.selectedCategories),function(a,i){return _c('div',{key:i,staticClass:"m-3"},[_vm._v(" "+_vm._s(a.name)+" "),_c('b-button',{staticClass:"btn-icon float-right",attrs:{"size":"xs","variant":"light-danger"},on:{"click":function($event){return _vm.deleteCategory(i)}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/General/Trash.svg"}})],1)])],1)}),0)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }